<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    type="table-heading,card"
    :loading="activity.isFirstLoad"
  >
    <VRow>
      <SaveHeader
        :owner="this"
        :mode="mode"
        :hide-back-button="hideBackButton"
        :align-alert-icon="isInviteButtonEnabled"
      >
        <template 
          v-if="isInviteButtonEnabled" 
          #response-action
        >
          <VBtn 
            color="info"
            class="ml-2"
            :loading="form.isLoading"
            @click="sendInvite"
          >
            Invite them instead?
          </VBtn>
        </template>
      </SaveHeader>
      <VCol style="flex: 1 1 auto">
        <VRow
          row
          wrap
          :class="{
            'mt-n8': hidePipelines,
          }"
        >
          <VCol
            md="6"
            cols="12"
          >
            <VCard>
              <VCardTitle>Details</VCardTitle>
              <VCardText>
                <VForm autocomplete="off">
                  <VCheckbox
                    v-if="hasTeamUser"
                    v-model="form.team_user.is_approved"
                    label="Is approved?"
                  />
                  <TextField
                    v-model="form.name"
                    label="Name"
                    placeholder="Enter name..."
                    autocomplete="off"
                    :error="$v.form.name.$error"
                    @input="$v.$touch()"
                    @blur="$v.$touch()"
                  />
                  <TextField
                    v-model="form.email"
                    label="Email"
                    autocomplete="off"
                    type="email"
                    :placeholder="form.email"
                    :error="$v.form.email.$error"
                    @input="$v.$touch()"
                    @blur="$v.$touch()"
                  />
                  <PasswordInput
                    v-if="canUpdatePassword"
                    v-model="form.password"
                    :editable="formHasId"
                  />
                  <Autocomplete
                    v-if="canUpdateRole"
                    v-model="form.team_user.role"
                    item-text="name"
                    item-value="id"
                    append-inner-icon="arrow-down"
                    placeholder="Select role..."
                    :items="validRoles($team.roles)"
                    :disabled="updateRoleIsDisabled"
                    :error="$v.form.team_user.role.$error"
                    @input="$v.$touch()"
                    @blur="$v.$touch()"
                  />
                  <h3 v-else>
                    Role: {{ form.role_name }}
                  </h3>
                  <GeoSelectCity
                    v-if="isFormReady && options.countries.length"
                    :current-city="form.city"
                    :current-city-id="form.city_id"
                    :countries="options.countries"
                    @citySelected="(id) => form.city_id = id"
                    @removeCity="form.city = form.city_id = null"
                  />
                </VForm>
              </VCardText>
            </VCard>
          </VCol>
          <VCol
            md="6"
            cols="12"
          >
            <VCard>
              <VCardTitle>Associations</VCardTitle>
              <VCardText>
                <Autocomplete
                  v-model="form.programs"
                  multiple
                  :items="options.programs"
                  :disabled="programs && programs.length > 0"
                  :placeholder="`Attach to ${featureName('Programs')}`"
                />
              </VCardText>
            </VCard>
            <VCard
              v-if="!form.id && !hidePipelines"
              class="mt-4"
            >
              <VCardTitle>{{ featureName('Pipeline', 'singularize') }}</VCardTitle>
              <VCardText>
                <VAlert
                  :value="true && !form.id"
                  type="info"
                >
                  NOTE: You can only set the {{ `${featureName('application', 'singularize').toLowerCase()} ${featureName('pipeline', 'singularize').toLowerCase()}` }} when creating a new user. You may manually attach them to another {{ featureName('pipeline', 'singularize').toLowerCase() }} once they exist by navigating to the {{ featureName('pipelines').toLowerCase() }} page.
                </VAlert>
              </VCardText>
              <VCardText v-if="!form.id && form.pipeline_application">
                <Autocomplete
                  v-model="form.pipeline_application.pipeline_id"
                  :items="options.pipelines"
                  :placeholder="`Attach to ${featureName('Pipeline', 'singularize')}`"
                />
                <Autocomplete
                  v-show="currentPipelineStages.length"
                  v-model="form.pipeline_application.current_stage_id"
                  :items="currentPipelineStages"
                  :placeholder="`Attach to ${featureName('Stage', 'singularize')}`"
                />
              </VCardText>
            </VCard>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import FormMixin from "@/mixins/Form";
import SaveHeader from "@/components/Elements/Navigation/SaveHeader";
import { mapActions } from "vuex";
import {
  required,
  minLength,
  between,
  requiredIf,
} from "vuelidate/lib/validators";
import PasswordInput from "@/components/Elements/Forms/PasswordInput";
export default {
  name: "UserSave",
  components: { SaveHeader, PasswordInput },
  mixins: [FormMixin],
  props: {
    programs: {
      type: Array,
      default: null,
    },
    hidePipelines: {
      type: Boolean,
      default: false,
    },
    hideBackButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modelType: "Users",
      showPassword: false,
      options: {
        calendar: [],
        role: [],
        deliverables: [],
        reports: [],
        supplementalUser: [],
        countries: [],
      },
      saveOnly: [
        "id",
        "name",
        "email",
        "photo_url",
        "password",
        "password_confirmation",
        "timezone",
        "time_format",
        "date_format",
        "profile",
        "city_id",
        "avatar",
        "add_existing_to_team",
        "team_id",
        "role_id",
        "programs",
        "team_user",
      ],
      defaultForm: {
        name: "",
        city_id: null,
        email: "",
        password: null,
        profile: {
          bio: "",
        },
        programs: this.programs || [],
        team_user: {
          role: "",
          is_approved: false,
        },
        pipeline_application: {
          type: "application-pipeline",
        },
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(4),
      },
      email: {
        required,
        minLength: minLength(4),
      },
      team_user: {
        role: {
          required,
        },
      },
      pipeline_application: {
        current_stage_id: requiredIf(function (applicationPipeline) {
          return (
            !this.form.id &&
            parseInt((applicationPipeline || {}).pipeline_id) > 0
          );
        }),
      },
    },
  },
  computed: {
    hasTeamUser() {
      return this.form.team_user instanceof Object;
    },
    canUpdateRole() {
      return (
        this.form.team_user instanceof Object &&
        this.form.team_user.role !== "owner"
      );
    },
    canUpdatePassword() {
      return !this.form.id;
    },
    updateRoleIsDisabled() {
      return (
        this.form.team_user instanceof Object &&
        this.form.team_user.role === "owner"
      );
    },
    currentPipelineStages() {
      const pipelineId = this.form.pipeline_application.pipeline_id;
      if (pipelineId) {
        return (
          this.options.pipelines.find((pipeline) => pipeline.id === pipelineId)
            .stages || []
        );
      }
      return [];
    },
    isInviteButtonEnabled() {
      return this.errors && this.errors[0] == "The email has already been taken.";
    },
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "users/getFormConfig",
      doSaveUser: "users/save",
      doGetUser: "users/getOne",
      doSendInvite: "team/invite",
    }),
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    onSave() {
      if (!this.form.password) {
        delete this.form.password;
      }
      this.form.team_id = this.$team.id;
      this.form.programs = this.prepareRelationIds(this.form.programs);
      this.form.role_id = this.form.team_user.role;
      this.form.add_existing_to_team = true;
      // Sending the team esses with the route middleware that checks for teams
      delete this.form.team;
      return this.saveFormModel(this.doSaveUser).then((user) => {
        this.form.team_user.role = this.form.team_user
          ? this.form.team_user.role
          : "mentor";
        this.$emit("updated", user);
      });
    },
    onGet(id) {
      return this.doGetUser(id).then((model) => {
        model.password = "";
        return model;
      });
    },
    sendInvite() {
      this.$set(this.form, "isLoading", true);
      this.doSendInvite(this.form)
        .then((result) => {
          this.$set(this.form, "isLoading", false);
          this.errors = [];
          this.onReset();
          this.$bus.$emit(
            "notificationMessage",
            `The invitation has been sent to ${result.email}.`
          );
        });
    },
  },
};
</script>

